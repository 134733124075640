import React, { useState } from 'react';
import { Button, TextField, Checkbox, FormControlLabel, Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

function RandomNumberGenerator() {
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(100);
    const [quantity, setQuantity] = useState(1);
    const [unique, setUnique] = useState(false);
    const [includeDecimals, setIncludeDecimals] = useState(false);
    const [decimalPlaces, setDecimalPlaces] = useState(2);
    const [seed, setSeed] = useState('');
    const [numbers, setNumbers] = useState([]);

    const [rng, setRng] = useState(null);

useEffect(() => {
    if (seed) {
        setRng(lcg(Number(seed)));
    } else {
        setRng(null);
    }
}, [seed]);

    function lcg(seed) {
        const a = 1664525;
        const c = 1013904223;
        const m = 2 ** 32; // 2^32
        let currentSeed = seed;
    
        return {
            next: function() {
                currentSeed = (a * currentSeed + c) % m;
                return currentSeed / m;
            }
        };
    }

    const generateRandomNumber = () => {
        const randomFunc = rng ? rng.next : Math.random;
    
        if (includeDecimals) {
            const random = (randomFunc() * (maxValue - minValue) + minValue).toFixed(decimalPlaces);
            return parseFloat(random);
        } else {
            return Math.floor(randomFunc() * (maxValue - minValue + 1) + minValue);
        }
    };
    

    const handleGenerate = () => {
        const generatedNumbers = [];

        for (let i = 0; i < quantity; i++) {
            let num = generateRandomNumber();

            if (unique) {
                while (generatedNumbers.includes(num)) {
                    num = generateRandomNumber();
                }
            }

            generatedNumbers.push(num);
        }

        setNumbers(generatedNumbers);
    };

    const handleCopyToClipboard = () => {
        const text = numbers.join(', ');
        navigator.clipboard.writeText(text);
    };

    return (
        <div>
            <Helmet>
                <title>Tools & Calculators - Random number generator</title>
                <meta name="description" content="Welcome to our advanced Random Number Generator. Whether you're looking to generate a single random number or a sequence of numbers, our tool offers a suite of features to cater to your needs. With options to set minimum and maximum values, generate unique numbers, include decimals, and even use a seed for reproducibility, you have full control over the randomness!" />
            </Helmet>
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
    <h1 style={{ textAlign: 'center', color: '#333' }}>Random Number Generator</h1>
    <p style={{ fontSize: '18px', lineHeight: '1.6' }}>
        Welcome to our advanced <strong>Random Number Generator</strong>. Whether you're looking to generate a single random number or a sequence of numbers, our tool offers a suite of features to cater to your needs. With options to set minimum and maximum values, generate unique numbers, include decimals, and even use a seed for reproducibility, you have full control over the randomness!
    </p>
    <p style={{ fontSize: '18px', lineHeight: '1.6' }}>
        This tool is perfect for a variety of applications, from simple random draws and lotteries to more complex statistical simulations and data analysis tasks. Plus, with our seed functionality, you can reproduce your results anytime.
    </p>
    <h2 style={{ color: '#444' }}>How to Use</h2>
    <ol style={{ fontSize: '18px', lineHeight: '1.6' }}>
        <li>Set your desired minimum and maximum values.</li>
        <li>Choose the quantity of random numbers you want to generate.</li>
        <li>Select additional options like unique numbers or including decimals as per your needs.</li>
        <li>If you want reproducible results, enter a seed value.</li>
        <li>Click on the "Generate" button to get your random numbers.</li>
        <li>Use the "Copy to Clipboard" button for easy sharing and saving.</li>
    </ol>
    <h2 style={{ color: '#444' }}>Why Use Our Random Number Generator?</h2>
    <p style={{ fontSize: '18px', lineHeight: '1.6' }}>
        Our <strong>Random Number Generator</strong> is more than just a simple number picker. It's a versatile tool designed with user needs in mind. Whether you're a student, a data analyst, a game developer, or just someone curious about randomness, our tool offers features that make generating random numbers a breeze. Plus, with our commitment to accuracy and reproducibility, you can trust the results you get.
    </p>
    <p style={{ fontSize: '18px', lineHeight: '1.6', textAlign: 'center' }}>
        Dive in and explore the world of randomness with our advanced Random Number Generator!
    </p>
</div>


        <Box p={4}>
            <Typography variant="h4" gutterBottom>Random Number Generator</Typography>
            <TextField label="Minimum Value" value={minValue} onChange={(e) => setMinValue(e.target.value)} />
            <TextField label="Maximum Value" value={maxValue} onChange={(e) => setMaxValue(e.target.value)} />
            <TextField label="Quantity" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
            <TextField label="Seed Value" value={seed} onChange={(e) => setSeed(e.target.value)} />
            <FormControlLabel control={<Checkbox checked={unique} onChange={() => setUnique(!unique)} />} label="Unique Numbers" />
            <FormControlLabel control={<Checkbox checked={includeDecimals} onChange={() => setIncludeDecimals(!includeDecimals)} />} label="Include Decimals" />
            {includeDecimals && <TextField label="Decimal Places" value={decimalPlaces} onChange={(e) => setDecimalPlaces(e.target.value)} />}
            <Button variant="contained" color="primary" onClick={handleGenerate}>Generate</Button>
            <Button variant="outlined" color="primary" onClick={handleCopyToClipboard}>Copy to Clipboard</Button>
            <Box mt={3}>
                <Typography variant="h6">Generated Numbers:</Typography>
                {numbers.map((num, index) => (
                    <Typography key={index}>{num}</Typography>
                ))}
            </Box>
        </Box>
        </div>
    );
}

export default RandomNumberGenerator;
