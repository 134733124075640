import { Container } from '@mui/material';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Home';
import PValue from './P-Value';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import ReactGA from 'react-ga4';
import PrivacyPolicy from './PrivacyPolicy';
import Navbar from './Navbar';
import Random from './Random';
import Statistics from './Statistics';
import TextTools from './TextTools';
import Footer from './Footer';
import RandomNumberGenerator from './RandomNumberGenerator';
import UserIPInfo from './IP';
import AboutPage from './About';
import CookieWarning from './CookieWarning';
import ElasticityCalculator from './Microeconomics';
import WheelOfFortune from './WheelOfFortune';
import DateTimeConverter from './DateTimeConververter';
import SavingsCalculator from './SavingsCalculator';
import { Helmet } from 'react-helmet';
import CookingConverter from './CookingConverter';
import LinearRegression from './LinearRegression';
import CurrencyConverter from './CurrencyConverter';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    }
  },
    palette: {
      primary: {
        main: '#3f51b5',
      },
      secondary: {
        main: '#f50057',
      },
      background: {
        default: '#f5f5f5',
      },
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
    },
  });

  const MainContent = styled(Container)`
    padding-bottom: 50px;  // Adjust this value based on the height of your footer
`;


function App() {

  React.useEffect(() => {
    ReactGA.initialize('G-3N0KRZB20K');
    //ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

    return (
        <ThemeProvider theme={theme}>
          <Helmet>
        <title>Tools and calculators</title>
        <meta name="description" content="Find all kinds of tools, calculators, generators, etc. to make your life easier!" />
      </Helmet>
          <Navbar />
          <MainContent>
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/p-value" element={<PValue />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/random" element={<Random />} />
                <Route path="/statistics" element={<Statistics />} />
                <Route path="/texttools" element={<TextTools />} />
                <Route path="/randomnumbergenerator" element={<RandomNumberGenerator />} />
                <Route path="*" element={<h1>Not Found</h1>} />
                <Route path="/whats-my-ip" element={<UserIPInfo />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/microeconomics" element={<ElasticityCalculator />} />
                <Route path="/wheeloffortune" element={<WheelOfFortune />} />
                <Route path="/datetimeconverter" element={<DateTimeConverter />} />
                <Route path="/savingscalculator" element={<SavingsCalculator />} />
                <Route path="/cookingconverter" element={<CookingConverter />} />
                <Route path="/linearregression" element={<LinearRegression />} />
                <Route path="/currencyconverter" element={<CurrencyConverter />} />
            </Routes>
        </Router>
        </MainContent>
        <Footer />
        <CookieWarning />
        </ThemeProvider>
    );
}

export default App;