import { Typography } from '@mui/material';
import { Helmet } from 'react-helmet';

function PrivacyPolicy() {
  return (
    <div>
      <Helmet>
                <title>Tools & Calculators - Privacy Policy</title>
                <meta name="description" content="Our Privacy policy" />
            </Helmet>
      <Typography variant="h4">Privacy Policy</Typography>
      <Typography paragraph>
      We respect your privacy and are committed to protecting it. This Privacy Policy explains how we collect, use, and safeguard your personal information.
      </Typography>

      <Typography variant="h5">
Information We Collect</Typography>
 <Typography paragraph>We may collect personal information such as your name, email address, and other details when you register on our site, subscribe to a newsletter, or fill out a form.
</Typography>

<Typography variant="h5">How We Use Your Information</Typography>
<Typography paragraph>
We may use the information we collect from you to personalize your experience, improve our website, send periodic emails, or respond to inquiries.
</Typography>

<Typography variant="h5">Protecting Your Information</Typography>
<Typography paragraph>
We implement security measures to maintain the safety of your personal information. Your personal information is stored behind secured networks and is only accessible by a limited number of persons who have special access rights.
</Typography>

<Typography variant="h5">Cookies</Typography>
<Typography paragraph>
We may use cookies to enhance your experience on our site. Cookies are small files that a site transfers to your computer's hard drive through your web browser (if you allow) that enables the site to recognize your browser and remember certain information.
</Typography>
<Typography variant="h5">Third-party Links</Typography>
<Typography paragraph>
Occasionally, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We have no responsibility or liability for the content and activities of these linked sites.
</Typography>
<Typography variant="h5">Consent</Typography>
<Typography paragraph>
By using our site, you consent to our Privacy Policy.
</Typography>
<Typography variant="h5">Changes to This Policy</Typography>
<Typography paragraph>
If we decide to change our privacy policy, we will post those changes on this page.
</Typography>
<Typography variant="h5">Contact Us</Typography>
<Typography paragraph>
If you have any questions regarding this Privacy Policy, you can contact us using the information on our website.
</Typography>    </div>
  );
}

export default PrivacyPolicy;