import React, { useState } from 'react';
import { Button, TextField, Paper } from '@mui/material';
import Confetti from 'react-confetti';

function WheelOfFortune() {
    const [items, setItems] = useState([]);
    const [angle, setAngle] = useState(0);
    const [showConfetti, setShowConfetti] = useState(false);

    const [winningItem, setWinningItem] = useState('');

    const handleInputChange = (event) => {
        const inputItems = event.target.value.split('\n').filter(item => item.trim() !== ''); // Filter out empty or whitespace-only strings
        setItems(inputItems);
    };

    const handleSpin = () => {
        const numberOfItems = items.length;
        const spins = Math.floor(Math.random() * (5 - 3 + 1) + 3); // Random spins between 3 to 5
        const selectedIndex = Math.floor(Math.random() * numberOfItems);
        const sliceAngle = 360 / numberOfItems;
        const rotation = (360 * spins) - (sliceAngle * selectedIndex) + (sliceAngle / 2); // Adjusted rotation

        setAngle(rotation);
        setWinningItem(null);

        setTimeout(() => {
            const winner = items[selectedIndex];
            setWinningItem(winner);
            setShowConfetti(true);
        }, 5000); // Assuming 5 seconds for the spin animation
    };


    const colors = ['#f2d7d5', '#d4e6f1', '#d5f5e3', '#fcf3cf']; // Four different colors

    return (
        <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
            <TextField
                multiline
                rows={6}
                variant="outlined"
                fullWidth
                placeholder="Enter items, one per line..."
                onChange={handleInputChange}
            />
            <Button variant="contained" color="primary" onClick={handleSpin} style={{ margin: '20px' }}>
                Spin the Wheel!
            </Button>
            <div style={{ position: 'relative' }}>
                <svg width="300" height="300" viewBox="0 0 300 300" style={{ transform: `rotate(${angle}deg)`, transition: 'transform 4s ease-out' }}>
                    {items.map((item, index, arr) => {
                        const sliceAngle = 360 / arr.length;
                        const startAngle = sliceAngle * index;
                        const endAngle = startAngle + sliceAngle;
                        const midAngle = startAngle + sliceAngle / 2;
                        const largeArcFlag = sliceAngle > 180 ? 1 : 0;
                        const color = colors[index % 4];
                        return (
                            <g key={index}>
                                <path
                                    d={`M 150 150 L ${150 + 150 * Math.cos(Math.PI * startAngle / 180)} ${150 + 150 * Math.sin(Math.PI * startAngle / 180)} A 150 150 0 ${largeArcFlag} 1 ${150 + 150 * Math.cos(Math.PI * endAngle / 180)} ${150 + 150 * Math.sin(Math.PI * endAngle / 180)} Z`}
                                    fill={color}
                                />
                                <text
                                    x={150 + 100 * Math.cos(Math.PI * midAngle / 180)}
                                    y={150 + 100 * Math.sin(Math.PI * midAngle / 180)}
                                    fill="black"
                                    fontSize="12"
                                    dy=".3em"
                                    textAnchor="middle"
                                >
                                    {item}
                                </text>
                            </g>
                        );
                    })}
                </svg>
                <div style={{ position: 'absolute', top: '10px', left: '50%', transform: 'translateX(-50%)', width: '0', height: '0', borderTop: '20px solid red', borderLeft: '10px solid transparent', borderRight: '10px solid transparent' }}></div>

                
            </div>

            <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                numberOfPieces={showConfetti ? 200 : 0}
                onConfettiComplete={() => setShowConfetti(false)}
            />

            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '2em',
                fontWeight: 'bold',
                opacity: winningItem ? 1 : 0,
                transition: 'opacity 0.5s'
            }}>
                {winningItem}
            </div>

            
        </Paper>
    );
}

export default WheelOfFortune;


            {/*<Sound url="/spin.mp3" playStatus={spinning.current ? Sound.status.PLAYING : Sound.status.STOPPED} />*/}


            