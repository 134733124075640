import React, { useState } from 'react';
import axios from 'axios';
import Alert from '@mui/lab/Alert';
import { Helmet } from 'react-helmet';
import { Box, Link, Paper, Typography, TextField, Button } from '@mui/material';

function LinearRegression() {
  const [xValues, setXValues] = useState(['']);
  const [yValues, setYValues] = useState(['']);
  const [equation, setEquation] = useState('');
  const [rSquared, setRSquared] = useState('');

  const calculateRegression = async () => {
    const response = await axios.post('https://tools-calculators.com/api/calculate-linear-regression', {
      xValues,
      yValues
    });
    setEquation(response.data.equation);
    setRSquared(response.data.rSquared);
  };

  return (
    <div>
      <Helmet>
        <title>Linear Regression Calculator</title>
        <meta name="description" content="Determine the relationship between two variables with our Linear Regression calculator." />
      </Helmet>

      <h1>Linear Regression Calculator</h1>
      <p>Find out the linear relationship between two sets of data points.</p>

      <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
        <Typography variant="h4" gutterBottom>
          Linear Regression Calculator: Illuminate the Relationship Between Variables
        </Typography>

        <Typography variant="body1" paragraph>
          Dive into the world of linear relationships with our state-of-the-art Linear Regression Calculator.
        </Typography>

        <Typography variant="h6">What is Linear Regression?</Typography>
        <Typography variant="body1" paragraph>
          Linear regression is a fundamental statistical method that unveils the relationship between two variables. By fitting the best linear relationship (a line) to a set of data points, linear regression provides insights into how changes in one variable might influence another.
        </Typography>

        <Typography variant="h6">Features of Our Tool:</Typography>
        <Box marginBottom={2}>
          <Typography variant="body1">1. <strong>User-Friendly Interface</strong>: Simply input your X and Y values, and let our calculator do the heavy lifting.</Typography>
          <Typography variant="body1">2. <strong>Instant Results</strong>: Get the equation of the regression line and the R-squared value, a measure of how well the line fits your data.</Typography>
          <Typography variant="body1">3. <strong>Clarity in Analysis</strong>: Understand the strength and direction of the relationship between your variables.</Typography>
        </Box>

        <Typography variant="h6">Points to Consider:</Typography>
        <Box marginBottom={2}>
          <Typography variant="body1">1. <strong>Assumptions</strong>: Linear regression assumes a linear relationship between the variables. Ensure your data meets this criterion for accurate results.</Typography>
          <Typography variant="body1">2. <strong>Outliers</strong>: Extreme values can skew results. It's crucial to clean and preprocess your data for the most accurate insights.</Typography>
          <Typography variant="body1">3. <strong>Interpretation</strong>: While the R-squared value indicates the goodness of fit, it's essential to consider other factors and tests in a comprehensive analysis.</Typography>
        </Box>

        <Typography variant="body1" paragraph>
          Whether you're a student delving into statistics or a professional seeking quick insights, our Linear Regression Calculator is a blend of accuracy and ease. Dive into the world of data and extract meaningful relationships, one regression at a time.
        </Typography>
      </Paper>

      {/* Input for x and y values */}
      <TextField 
        label="X Values" 
        variant="outlined" 
        fullWidth 
        value={xValues} 
        onChange={e => setXValues(e.target.value)} 
        helperText="Enter comma-separated values for X"
        style={{ marginBottom: '20px' }}
      />

      <TextField 
        label="Y Values" 
        variant="outlined" 
        fullWidth 
        value={yValues} 
        onChange={e => setYValues(e.target.value)} 
        helperText="Enter comma-separated values for Y"
        style={{ marginBottom: '20px' }}
      />

      <Button variant="contained" color="primary" onClick={calculateRegression}>Calculate Regression</Button>
      {equation && (
        <Alert severity="info" style={{ marginTop: '20px' }}>
          <Typography variant="body1">Equation: {equation}</Typography>
          <Typography variant="body1">R-Squared: {rSquared}</Typography>
        </Alert>
      )}

      <p><Link href="/">Back to Home</Link></p>
    </div>
  );
}

export default LinearRegression;
