import React, { useState } from 'react';
import axios from 'axios';
import Alert from '@mui/lab/Alert';
import { Helmet } from 'react-helmet';
import { Link } from '@mui/material';

function PValue() {
  const [observedValue, setObservedValue] = useState('');
  const [mean, setMean] = useState('');
  const [standardDeviation, setStandardDeviation] = useState('');
  const [pValue, setPValue] = useState('');

  const calculatePValue = async () => {
    const response = await axios.post('https://tools-calculators.com/api/calculate-p-value', {
      observedValue,
      mean,
      standardDeviation
    });
    setPValue(response.data.pValue);
  };

  const getPValueAlertSeverity = () => {
    if (pValue < 0.01) {
      return 'error';
    } else if (pValue < 0.05) {
      return 'warning';
    } else if (pValue < 0.1) {
      return 'info';
    } else {
      return 'success';
    }
  };

  const getPValueExplanation = () => {
    if (pValue < 0.01) {
      return 'The p-value is less than 0.01, which is typically considered very strong evidence against the null hypothesis.';
    } else if (pValue < 0.05) {
      return 'The p-value is less than 0.05, which is typically considered strong evidence against the null hypothesis.';
    } else if (pValue < 0.1) {
      return 'The p-value is less than 0.1, which is typically considered weak evidence against the null hypothesis.';
    } else {
      return 'The p-value is greater than or equal to 0.1, which indicates that there is not enough evidence to reject the null hypothesis.';
    }
  };

  return (
    <div>
      <Helmet>
        <title>P-Value Calculator</title>
        <meta name="description" content="Calculate the P-value for your statistical test with our easy-to-use P-value calculator." />
      </Helmet>

      <h1>P-Value Calculator</h1>
      <p>Our P-value calculator makes it easy to calculate the P-value for your statistical test. Just enter your observed value, mean, and standard deviation, and get the P-value instantly.</p>

      <input type="number" value={observedValue} onChange={e => setObservedValue(e.target.value)} placeholder="Observed Value" />
      <input type="number" value={mean} onChange={e => setMean(e.target.value)} placeholder="Mean" />
      <input type="number" value={standardDeviation} onChange={e => setStandardDeviation(e.target.value)} placeholder="Standard Deviation" />
      <button onClick={calculatePValue}>Calculate P-Value</button>
      {pValue && (
        <Alert severity={getPValueAlertSeverity()}>
          <p>P-Value: {pValue}</p>
          <p>{getPValueExplanation()}</p>
        </Alert>
      )}

      <p>
      <Link href="/">Back to Home</Link></p>
    </div>
  );
}

export default PValue;
