import { Box, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Home() {
    return (
        <><Helmet>
        <title>Tools and calculators</title>
        <meta name="description" content="Find all kinds of tools, calculators, generators, etc. to make your life easier!" />
      </Helmet>
        <Box p={4} bgcolor="background.paper" borderRadius={2} boxShadow={2}>
            <Typography variant="h2" gutterBottom color="primary.main">Statistics</Typography>
            <Typography variant="h4" gutterBottom>Tests</Typography>
            <Typography variant="body1" paragraph>
                Our P-value calculator makes it easy to calculate the P-value for your statistical test. Just enter your observed value, mean, and standard deviation, and get the P-value instantly.
            </Typography>
            <List>
                <ListItem button component={Link} to="/p-value">
                    <ListItemIcon><EqualizerIcon color="action" /></ListItemIcon>
                    <ListItemText primary="P-Value Calculator" />
                </ListItem>
            </List>
            
            <Typography variant="h4" gutterBottom>Regression</Typography>
            <List>
                <ListItem button component={Link} to="/linearregression">
                    <ListItemIcon><EqualizerIcon color="action" /></ListItemIcon>
                    <ListItemText primary="Linear Regression calculator" />
                </ListItem>
            </List>

            <Typography variant="h2" gutterBottom color="primary.main">Random Numbers & Items</Typography>
            <List>
            <ListItem button component={Link} to="/randomnumbergenerator">
                    <ListItemIcon><EqualizerIcon color="action" /></ListItemIcon>
                    <ListItemText primary="Random number generator" />
                </ListItem>
            <ListItem button component={Link} to="/wheeloffortune">
                <ListItemIcon><EqualizerIcon color="action" /></ListItemIcon>
                <ListItemText primary="Wheel of fortune: pick an item" />
            </ListItem>
                {/* Add links and descriptions for random number tools when available */}
            </List>

            <Typography variant="h2" gutterBottom color="primary.main">Finances</Typography>
            <List>
            <ListItem button component={Link} to="/savingscalculator">
                    <ListItemIcon><EqualizerIcon color="action" /></ListItemIcon>
                    <ListItemText primary="Savings calculator" />
                </ListItem>
            </List>

            <Typography variant="h2" gutterBottom color="primary.main">Date and Time</Typography>
            <List>
            <ListItem button component={Link} to="/datetimeconverter">
                    <ListItemIcon><EqualizerIcon color="action" /></ListItemIcon>
                    <ListItemText primary="Date and time converter" />
                </ListItem>
            </List>

            <Typography variant="h2" gutterBottom color="primary.main">Text Transformation</Typography>
            <List>
                <ListItem button component={Link} to="/texttools">
                    <ListItemIcon><TextFieldsIcon color="action" /></ListItemIcon>
                    <ListItemText primary="Change text from all lower case to upper case" />
                </ListItem>
                <ListItem button component={Link} to="/texttools">
                    <ListItemIcon><TextFieldsIcon color="action" /></ListItemIcon>
                    <ListItemText primary="Change text from all upper case to lower case" />
                </ListItem>
                <ListItem button component={Link} to="/texttools">
                    <ListItemIcon><TextFieldsIcon color="action" /></ListItemIcon>
                    <ListItemText primary="Count words in a text" />
                </ListItem>
                <ListItem button component={Link} to="/texttools">
                    <ListItemIcon><TextFieldsIcon color="action" /></ListItemIcon>
                    <ListItemText primary="Count characters in a text" />
                </ListItem>
            </List>

            <Typography variant="h2" gutterBottom color="primary.main">Tech tools</Typography>
            <List>
                <ListItem button component={Link} to="/whats-my-ip">
                    <ListItemIcon><TextFieldsIcon color="action" /></ListItemIcon>
                    <ListItemText primary="What's my IP address?" />
                </ListItem>
            </List>

            <Typography variant="h2" gutterBottom color="primary.main">Economics tools</Typography>
            <List>
                <ListItem button component={Link} to="/microeconomics">
                    <ListItemIcon><TextFieldsIcon color="action" /></ListItemIcon>
                    <ListItemText primary="Microeconomics 101 tools and calculators" />
                </ListItem>
            </List>

            <Typography variant="h2" gutterBottom color="primary.main">Cooking</Typography>
            <List>
                <ListItem button component={Link} to="/cookingconverter">
                    <ListItemIcon><TextFieldsIcon color="action" /></ListItemIcon>
                    <ListItemText primary="Convert weight to volume" />
                </ListItem>
            </List>

            <Typography variant="h2" gutterBottom color="primary.main">Finance</Typography>
            <List>
                <ListItem button component={Link} to="/currencyconverter">
                    <ListItemIcon><TextFieldsIcon color="action" /></ListItemIcon>
                    <ListItemText primary="Currency Converter" />
                </ListItem>
            </List>
        </Box></>
    );
}

export default Home;
