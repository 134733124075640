import React from 'react';
import { Typography, Container, Box, Avatar, Button, Link } from '@mui/material';
import { Helmet } from 'react-helmet';

function AboutPage() {
    return (
        <div>
            <Helmet>
        <title>Tools and calculators - About</title>
        <meta name="description" content="Our tool not only reveals your unique IP but also provides additional information like your city, region, country, and ISP. Whether you're troubleshooting, setting up a server, or just curious, we've got you covered." />
      </Helmet>
        <Container>
            <Box mt={4} mb={4} display="flex" flexDirection="column" alignItems="center">
                <Avatar 
                    src="https://media.licdn.com/dms/image/D4E03AQHRQ4F9008qmg/profile-displayphoto-shrink_800_800/0/1677767731786?e=2147483647&v=beta&t=PJ_tOc7eBTPnwxjEQBm8CcY7SeUp2B4Ku4X03HF4Iuo" 
                    alt="Profile Picture" 
                    sx={{ width: 120, height: 120, mb: 2 }}
                />
                <Typography variant="h2" gutterBottom>
                    About
                </Typography>
                <Typography variant="body1" paragraph>
                    In our fast-paced world, it's the small tools that can make a significant difference. Whether it's a calculator that saves you a few minutes of manual computation or a text transformation tool that streamlines your work, these utilities can make life a lot easier.
                </Typography>
                <Typography variant="body1" paragraph>
                    My motivation behind creating these tools is simple: making people's lives easier. With a diverse background in economics, law, political science, and tech, I've developed a unique perspective that allows me to understand the needs of users from various fields and deliver quality tools that cater to those needs.
                </Typography>
                <Typography variant="body1" paragraph>
                    This platform is a testament to my commitment to providing valuable and user-friendly tools. I'm always eager to hear from users. If you have ideas or suggestions for new features, please reach out. Your feedback helps shape the future of this platform.
                </Typography>
                <Typography variant="body1" paragraph>
                    If you find value in the tools provided and wish to support the project, consider making a small donation:
                </Typography>
                <Typography variant="body1" paragraph>
                    Thank you for your support!<br />
                    Philip
                </Typography>
                <Button variant="contained" color="primary" component={Link} href="https://ko-fi.com/toolscalculators" target="_blank" rel="noopener noreferrer">
                    Support on Ko-fi
                </Button>
            </Box>
        </Container>
        </div>
    );
}

export default AboutPage;
