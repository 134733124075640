import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, TextField, MenuItem, Button, Typography, CircularProgress } from '@mui/material';

const CurrencyConverter = () => {
    const [amount, setAmount] = useState(1);
    const [fromCurrency, setFromCurrency] = useState('USD');
    const [toCurrency, setToCurrency] = useState('EUR');
    const [exchangeRate, setExchangeRate] = useState(0);
    const [currencies, setCurrencies] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchCurrencies = async () => {
            try {
                const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
                setCurrencies(Object.keys(response.data.rates));
            } catch (error) {
                console.error('Error fetching currencies', error);
            }
        };

        fetchCurrencies();
    }, []);

    useEffect(() => {
        if (fromCurrency && toCurrency) {
            const fetchExchangeRate = async () => {
                setLoading(true);
                try {
                    const response = await axios.get(`https://api.exchangerate-api.com/v4/latest/${fromCurrency}`);
                    setExchangeRate(response.data.rates[toCurrency]);
                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching exchange rate', error);
                    setLoading(false);
                }
            };
            fetchExchangeRate();
        }
    }, [fromCurrency, toCurrency]);

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" style={{ margin: '20px 0' }}>Real-Time Currency Converter</Typography>
            <h1>Convert Currencies in Real-Time with Our Free Online Tool</h1>

<p>Stay updated with the latest currency exchange rates using our Real-Time Currency Converter. Instantly convert between different currencies without any hassle. Ideal for travelers, forex traders, and anyone needing quick currency conversions. No sign-up required and absolutely free!</p>


            {loading ? <CircularProgress /> : (
                <>
                    <TextField
                        label="Amount"
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        select
                        label="From"
                        value={fromCurrency}
                        onChange={(e) => setFromCurrency(e.target.value)}
                        fullWidth
                        margin="normal"
                    >
                        {currencies.map(currency => (
                            <MenuItem key={currency} value={currency}>{currency}</MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        label="To"
                        value={toCurrency}
                        onChange={(e) => setToCurrency(e.target.value)}
                        fullWidth
                        margin="normal"
                    >
                        {currencies.map(currency => (
                            <MenuItem key={currency} value={currency}>{currency}</MenuItem>
                        ))}
                    </TextField>
                    <Typography variant="h6">Converted Amount: {(amount * exchangeRate).toFixed(2)}</Typography>
                </>
            )}
        </Container>
    );
};

export default CurrencyConverter;
