import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Divider, Grid, Paper, Typography } from '@mui/material';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const SavingsCalculator = () => {
  const [monthlyAmount, setMonthlyAmount] = useState(1);
  const [years, setYears] = useState(0);
  const [interestRate, setInterestRate] = useState(1);
  const [interestRate2, setInterestRate2] = useState(1);
  
  const [desiredAmount, setDesiredAmount] = useState(1);
  const [desiredYears, setDesiredYears] = useState(1);

  const calculateEndAmount = () => {
    const months = years * 12;
    const monthlyRate = interestRate ? (interestRate / 12 / 100) : 0;

    if (monthlyRate === 0) {
        return monthlyAmount * months;
    }

    const FV = monthlyAmount * (((1 + monthlyRate) ** months - 1) / monthlyRate);
    return FV;
};

const calculateRequiredMonthlySavings = () => {
    const months = desiredYears * 12;
    const monthlyRate = interestRate2 ? (interestRate2 / 12 / 100) : 0;

    if (monthlyRate === 0) {
        return desiredAmount / months;
    }

    const P = desiredAmount / ((((1 + monthlyRate) ** months - 1) / monthlyRate));
    return P;
};



const generateChartData = () => {
    const months = years * 12;
    const monthlyRate = interestRate ? (interestRate / 12 / 100) : 0;

    const monthLabels = [...Array(months).keys()].map(m => `Month ${m + 1}`);
    const savingsData = [];

    let total = 0;
    for (let i = 0; i < months; i++) {
        total *= (1 + monthlyRate);  // Apply the interest first
        total += parseFloat(monthlyAmount);  // Then, add the monthly savings
        savingsData.push(total);
    }

    return {
        labels: monthLabels,
        datasets: [{
            label: 'Savings Over Time',
            data: savingsData,
            fill: false,
            borderColor: '#3f51b5',
            tension: 0.1
        }]
    };
};

const generateRequiredSavingsChartData = () => {
    const months = desiredYears * 12;
    const monthlyRate = interestRate2 ? (interestRate2 / 12 / 100) : 0;
    const requiredMonthlySavings = calculateRequiredMonthlySavings();

    const monthLabels = [...Array(months).keys()].map(m => `Month ${m + 1}`);
    const savingsData = [];

    let total = 0;
    for (let i = 0; i < months; i++) {
        total *= (1 + monthlyRate);  // Apply the interest first
        total += parseFloat(requiredMonthlySavings);  // Then, add the required monthly savings
        savingsData.push(total);
    }

    return {
        labels: monthLabels,
        datasets: [{
            label: 'Required Monthly Savings Over Time',
            data: savingsData,
            fill: false,
            borderColor: '#ff5722',  // Different color for distinction
            tension: 0.1
        }]
    };
};

  return (
    <Box>
        <Typography variant="h2" gutterBottom>
            Ultimate Savings Calculator
        </Typography>
        
        <Typography variant="body1" paragraph>
            Welcome to the Ultimate Savings Calculator – your go-to tool for planning your financial future. Whether you're saving for a big purchase, a dream vacation, or retirement, our calculator helps you figure out how much you need to save each month to reach your financial goals.
        </Typography>

        <Divider style={{ margin: '20px 0' }} />

        <Typography variant="h3" gutterBottom>
            Why Use Our Savings Calculator?
        </Typography>

        <Typography variant="body1" paragraph>
            <ul>
                <li><strong>Accuracy:</strong> Our tool uses the compound interest formula to ensure you get precise results every time.</li>
                <li><strong>Flexibility:</strong> Want to know how much you'll accumulate by saving a fixed amount every month? Or figure out how much you need to save to reach a particular sum? We've got you covered on both fronts.</li>
                <li><strong>Knowledge:</strong> Financial literacy is a valuable skill. By understanding how savings and interest rates work, you can make informed decisions about your finances.</li>
            </ul>
        </Typography>


    <Paper elevation={3} style={{ padding: '20px', maxWidth: '800px', margin: 'auto', marginTop: '50px' }}>
            <Typography variant="h5" gutterBottom>
                Savings Calculator
            </Typography>
            <Typography variant="h4" gutterBottom>
                            How much will I have after saving a certain amount per month for a certain number of years?
                        </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                
                    <TextField
                        fullWidth
                        value={monthlyAmount}
                        onChange={(e) => setMonthlyAmount(e.target.value)}
                        label="Monthly Amount"
                        type="number"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        value={years}
                        onChange={(e) => setYears(e.target.value)}
                        label="Years"
                        type="number"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        value={interestRate}
                        onChange={(e) => setInterestRate(e.target.value)}
                        label="Annual Interest Rate (%)"
                        type="number"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        If I save the above amount per month for the given years at the specified interest rate, I will have $ {calculateEndAmount().toFixed(2)} in the end.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h6" style={{ marginTop: '20px' }}>
                Savings Growth Visualization
                </Typography>
                
                <Line 
                data={generateChartData()}
                options={{
                    plugins: {
                        legend: {
                            display: true,
                            position: 'top',
                        }
                    },
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }}
                />
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                How much do I need to save per month to have a certain amount after a certain number of years?
                </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        value={desiredAmount}
                        onChange={(e) => setDesiredAmount(e.target.value)}
                        label="Desired Amount"
                        type="number"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        value={desiredYears}
                        onChange={(e) => setDesiredYears(e.target.value)}
                        label="Years for Desired Amount"
                        type="number"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        value={interestRate2}
                        onChange={(e) => setInterestRate2(e.target.value)}
                        label="Annual Interest Rate (%)"
                        type="number"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        If I want to have the desired amount after the specified years at the given interest rate, I need to save $ {calculateRequiredMonthlySavings().toFixed(2)} per month.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
            <Line 
    data={generateRequiredSavingsChartData()}
    options={{
        plugins: {
            legend: {
                display: true,
                position: 'top',
            }
        },
        scales: {
            y: {
                beginAtZero: true
            }
        }
    }}
/>
            </Grid>
        </Paper>

        <Typography variant="h3" gutterBottom>
            How Compound Interest Works
        </Typography>

        <Typography variant="body1" paragraph>
            Compound interest is the eighth wonder of the world. He who understands it, earns it; he who doesn't, pays it. Unlike simple interest, which only grows based on the principal amount, compound interest grows on both the principal and accumulated interest. This means your savings grow at an exponential rate, helping you reach your financial goals faster.
        </Typography>

        <Divider style={{ margin: '20px 0' }} />

        <Typography variant="h3" gutterBottom>
            Start Saving Today!
        </Typography>

        <Typography variant="body1" paragraph>
            The earlier you start saving, the better. Even if you start with a small amount, the power of compound interest can result in significant growth over time. So, plug in your numbers and see the magic happen. Remember, the best time to start was yesterday. The second best time is now.
        </Typography>

    </Box>
  );
};

export default SavingsCalculator;
