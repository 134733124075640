import React, { useState, useEffect } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel, Box, Typography } from '@mui/material';

const densities = {
    'water': 1,
    'all-purpose flour': 0.53,
    'granulated sugar': 0.85,
    'milk': 1.03,
    'honey': 1.42,
    'vegetable oil': 0.92,
    'butter': 0.96,
    'cocoa powder': 0.52,
    'rice (uncooked)': 0.85,
    'salt': 1.25,
    'brown sugar (packed)': 0.95,
    'powdered sugar': 0.56,
    'maple syrup': 1.37,
    'corn syrup': 1.33,
    'egg (whole)': 1.03,
    'oats': 0.43,
    'chocolate chips': 0.64,
    'raisins': 0.72,
    'almonds': 0.92,
    'peanut butter': 1.1,
    'molasses': 1.42,
    'cornmeal': 0.68,
    'breadcrumbs': 0.4,
    'cheddar cheese (shredded)': 0.45,
    'parmesan (grated)': 0.88,
    'yogurt': 1.01,
    'cream (heavy)': 0.994
};

const conversionTable = {

    
    temperature: {
        celsius: x => x,
        fahrenheit: x => x * 9/5 + 32
    },

    weight: {
        grams: 1,
        kilograms: 0.001,
        ounces: 28.3495,
        pounds: 0.00220462,
        milliliters: 1 // This represents 1g/mL for water but will change based on ingredient

    },
    volume: {
        milliliters: 1,
        liters: 0.001,
        teaspoons: 5,
        tablespoons: 15,
        fluidOunces: 28.4131,
        cups: 284.131,
        pints: 568.261,
        quarts: 1136.52,
        gallons: 4546.09
    },
    temperature: {
        celsius: 1,
        fahrenheit: (val) => (val * 9/5) + 32
    }
};

const CookingConverter = () => {
    const [unitType, setUnitType] = useState('weight');
    const [sourceUnit, setSourceUnit] = useState('grams');
    const [targetUnit, setTargetUnit] = useState('ounces');
    const [ingredient, setIngredient] = useState('water');
    const [sourceValue, setSourceValue] = useState('');
    const [convertedValue, setConvertedValue] = useState('');

    useEffect(() => {
        handleConversion();
    }, [sourceValue, sourceUnit, targetUnit, ingredient]);

    const handleConversion = () => {
        let valueInBaseUnit;
    
        if (unitType === 'temperature') {
            if (sourceUnit === 'celsius') {
                valueInBaseUnit = parseFloat(sourceValue);
            } else {
                valueInBaseUnit = (parseFloat(sourceValue) - 32) * 5/9;
            }
        } else {
            valueInBaseUnit = parseFloat(sourceValue) * conversionTable[unitType][sourceUnit];
        }
    
        if ((unitType === 'weight' && targetUnit === 'milliliters') || 
            (sourceUnit === 'milliliters' && unitType === 'weight')) {
            valueInBaseUnit *= densities[ingredient];
        }
    
        let targetValue;
        if (unitType === 'temperature') {
            targetValue = conversionTable[unitType][targetUnit](valueInBaseUnit);
        } else {
            targetValue = valueInBaseUnit / conversionTable[unitType][targetUnit];
        }
    
        if ((unitType === 'weight' && sourceUnit === 'milliliters') || 
            (targetUnit === 'milliliters' && unitType === 'weight')) {
            targetValue /= densities[ingredient];
        }
    
        setConvertedValue(targetValue);
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Cooking Unit Converter
            </Typography>

            <FormControl fullWidth margin="normal">
                <InputLabel>Type</InputLabel>
                <Select value={unitType} onChange={(e) => setUnitType(e.target.value)}>
                    <MenuItem value="weight">Weight</MenuItem>
                    <MenuItem value="volume">Volume</MenuItem>
                    <MenuItem value="temperature">Temperature</MenuItem>
                </Select>
            </FormControl>

            {unitType === 'weight' && (
                <FormControl fullWidth margin="normal">
                    <InputLabel>Ingredient</InputLabel>
                    <Select value={ingredient} onChange={(e) => setIngredient(e.target.value)}>
                        {Object.keys(densities).map(ing => (
                            <MenuItem key={ing} value={ing}>{ing}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}

            <TextField
                fullWidth
                margin="normal"
                label="From Value"
                value={sourceValue}
                onChange={(e) => setSourceValue(e.target.value)}
            />

            <FormControl fullWidth margin="normal">
                <InputLabel>From Unit</InputLabel>
                <Select value={sourceUnit} onChange={(e) => setSourceUnit(e.target.value)}>
                    {Object.keys(conversionTable[unitType]).map(unit => (
                        <MenuItem key={unit} value={unit}>{unit}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
                <InputLabel>To Unit</InputLabel>
                <Select value={targetUnit} onChange={(e) => setTargetUnit(e.target.value)}>
                    {Object.keys(conversionTable[unitType]).map(unit => (
                        <MenuItem key={unit} value={unit}>{unit}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <TextField
                fullWidth
                margin="normal"
                label="Converted Value"
                value={convertedValue}
                disabled
            />
        </Box>
    );
}

export default CookingConverter;
