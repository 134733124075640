import React, { useState } from 'react';
import { Button, TextField, Box, Typography } from '@mui/material';
import { Divider, ListItem, ListItemIcon, List, ListItemText } from '@mui/material';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import TransformIcon from '@mui/icons-material/Transform';
import { Helmet } from 'react-helmet';

function TextTools() {

    const [beforeText, setBeforeText] = useState('');
    const [afterText, setAfterText] = useState('');
    const [count, setCount] = useState(0);
    const [countWithSpaces, setCountWithSpaces] = useState(0);
    const [countWithoutSpaces, setCountWithoutSpaces] = useState(0);

    const handleUppercase = () => {
        setAfterText(beforeText.toUpperCase());
    };

    const handleLowercase = () => {
        setAfterText(beforeText.toLowerCase());
    };

    function handleAlternateCase() {
        let isNextUpper = true; // Start with uppercase
        let result = '';
    
        for (let char of beforeText) {
            if (char.match(/[a-zA-Z]/)) { // Check if the character is alphabetic
                if (isNextUpper) {
                    result += char.toUpperCase();
                } else {
                    result += char.toLowerCase();
                }
                isNextUpper = !isNextUpper; // Toggle the case for the next character
            } else {
                result += char; // If it's not an alphabetic character, just append it as it is
            }
        }
    
        setAfterText(result);
    }
    
    const handleWordCount = () => {
        const countWords = beforeText.split(/\s+/).filter(Boolean).length;
        setCount(countWords);
        //setAfterText(`Word Count: ${count}`);
    };

    const handleCharCountWithSpaces = () => {
        const countWithSpaces = beforeText.length;
        setCountWithSpaces(countWithSpaces); 
     };

    const handleCharCountWithoutSpaces = () => {
        const countWithoutSpaces = beforeText.replace(/\s+/g, '').length;
        setCountWithoutSpaces(countWithoutSpaces); 
     };
    

    return (
        <div>
            <Helmet>
                <title>Tools & Calculators - Random number generator</title>
                <meta name="description" content="Our advanced tool is designed to cater to your every need, from changing text cases to counting words and characters. Whether you're aiming to count words in an essay, change uppercase letters to lowercase, or analyze the structure of your content, we've got you covered." />
            </Helmet>
          <Box p={4} bgcolor="background.paper" borderRadius={2} boxShadow={2}>
            <Typography variant="h2" gutterBottom color="primary.main">Advanced Text Transformation & Analysis Tool</Typography>
            <Typography variant="body1" paragraph>
                Looking for a quick and efficient way to transform and analyze your text? Our advanced tool is designed to cater to your every need, from changing text cases to counting words and characters. Whether you're aiming to count words in an essay, change uppercase letters to lowercase, or analyze the structure of your content, we've got you covered.
            </Typography>
            
            <Typography variant="h4" gutterBottom color="secondary.main">Features:</Typography>
            <List>
                <ListItem>
                    <ListItemIcon><FormatListNumberedIcon color="action" /></ListItemIcon>
                    <ListItemText primary="Word Count: Easily determine the number of words in your text. Perfect for essays, articles, and other content where word limits matter." />
                </ListItem>
                <ListItem>
                    <ListItemIcon><TextFieldsIcon color="action" /></ListItemIcon>
                    <ListItemText primary="Character Count: Get both the total character count, including spaces, and the count without spaces. Essential for precise content length measurements." />
                </ListItem>
                <ListItem>
                    <ListItemIcon><TransformIcon color="action" /></ListItemIcon>
                    <ListItemText primary="Change Text Case: Convert text to uppercase, lowercase, title case, or sentence case with a single click. Looking to mix things up with your text? You've come to the right place! Our tool lets you transform your messages into a fun and trendy style, often seen in playful chats and social media posts (e.g. in the famous spongebob meme). Some might call it 'alternate case' or 'alternating caps', but no matter the name, it's a cool way to make your text stand out. Just type or paste your message, hit the button, and watch the magic happen. Perfect for those who love to add a touch of uniqueness to their texts!" /><b>Alternate Case Generator</b>
                </ListItem>
            </List>

            <Divider variant="middle" sx={{ my: 2 }} />

            <Typography variant="h4" gutterBottom color="secondary.main">Usage:</Typography>
            <Typography variant="body1" paragraph>
                Simply paste your text into the "Before" box, select the desired transformation or analysis function, and view the results in the "After" box. It's that simple!
            </Typography>

            <Typography variant="h4" gutterBottom color="secondary.main">Conclusion:</Typography>
            <Typography variant="body1">
                Our tool is designed with user experience in mind, ensuring quick and accurate results. Whether you're a student, writer, or professional, this tool is tailored to meet your text transformation and analysis needs. Bookmark this page for easy access and enhance your writing process!
            </Typography>

        </Box>

        <Box p={4}>
            <TextField
                label="Before"
                multiline
                rows={10}
                variant="outlined"
                fullWidth
                value={beforeText}
                onChange={(e) => {setBeforeText(e.target.value); 
                    handleWordCount();
                    handleCharCountWithSpaces();
                    handleCharCountWithoutSpaces();
                }}
            />
            <Box my={2}>
                <Button onClick={handleUppercase}>Uppercase</Button>
                <Button onClick={handleLowercase}>Lowercase</Button>
                <Button onClick={handleAlternateCase}>AlTeRnAtE cAsE / Studly caps / Sticky Caps</Button>
                {/* ... other transformation buttons ... */}<br />
                <Typography variant="paragraph">
                Word Count: {count}<br />
                Characters without spaces: {countWithoutSpaces} <br />
                Characters with spaces: {countWithSpaces}</Typography>
             
            </Box>
            <TextField
                label="After"
                multiline
                rows={10}
                variant="outlined"
                fullWidth
                value={afterText}
                disabled
            />
        </Box>
        </div>
    );
}

export default TextTools;
