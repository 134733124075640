import React from 'react';
import { AppBar, Toolbar, Typography, Button, Link } from '@mui/material';
import { styled } from '@mui/system';

const StyledAppBar = styled(AppBar)`
    background-color: #3f51b5;  // You can adjust this color
`;

const StyledTitle = styled(Typography)`
    flex-grow: 1;
`;

const NavButton = styled(Button)`
    margin-left: 16px;
`;

const Logo = styled(Link)`
    color: #fff;
`;

function Navbar() {
    return (
        <StyledAppBar position="static">
            <Toolbar>
                <StyledTitle variant="h6">
                    <Logo href="/">Every tool you need! Calculators, converters, etc.</Logo>
                </StyledTitle>
                <NavButton color="inherit" href="/statistics">Statistics</NavButton>
                <NavButton color="inherit" href="/random">Random</NavButton>
                <NavButton color="inherit" href="/texttools">Text Tools</NavButton>
            </Toolbar>
        </StyledAppBar>
    );
}

export default Navbar;