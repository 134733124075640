import React, { useState } from 'react';
import { Alert, Box, Button, TextField, Typography } from '@mui/material';
import axios from 'axios';

function ElasticityCalculator() {

    //PED
    const [P1, setP1] = useState('');
    const [P2, setP2] = useState('');
    const [Q1, setQ1] = useState('');
    const [Q2, setQ2] = useState('');
    const [result, setResult] = useState(null);
    const [interpretation, setInterpretation] = useState('');

    const calculatePED = async () => {
        try {
            const response = await axios.post('/api/calculate-ped', { P1, P2, Q1, Q2 });
            const PED = response.data.PED;
            setResult(PED);

            // Interpretation
            if (PED > 1) {
                setInterpretation('The demand is elastic.');
            } else if (PED < 1) {
                setInterpretation('The demand is inelastic.');
            } else {
                setInterpretation('The demand is unitary elastic.');
            }

        } catch (error) {
            console.error("Error calculating PED:", error);
        }
    };

    // YED
    const [changeInQuantity, setChangeInQuantity] = useState('');
    const [changeInIncome, setChangeInIncome] = useState('');
    const [resultYED, setResultYED] = useState('');

    const calculateYED = async () => {
        // Assuming you have an endpoint /calculate-yed on your server
        const response = await axios.post('/api/calculate-yed', {
            changeInQuantity,
            changeInIncome
        });
        setResultYED(response.data.yed);
    };

    const interpretYED = (yed) => {
        if (yed > 0) return "The good is a normal good.";
        if (yed < 0) return "The good is an inferior good.";
        return "The good is income inelastic.";
    };


    // XED

    const [changeInQuantityA, setChangeInQuantityA] = useState('');
    const [changeInPriceB, setChangeInPriceB] = useState('');
    const [resultXED, setResultXED] = useState('');

    const calculateXED = async () => {
        const response = await axios.post('/api/calculate-xed', {
            changeInQuantityA,
            changeInPriceB
        });
        setResultXED(response.data.xed);
    };

    const interpretXED = (xed) => {
        if (xed > 0) return "The goods are substitutes.";
        if (xed < 0) return "The goods are complements.";
        return "The goods are unrelated.";
    };

    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField label="Initial Price (P1)" value={P1} onChange={(e) => setP1(e.target.value)} />
            <TextField label="New Price (P2)" value={P2} onChange={(e) => setP2(e.target.value)} />
            <TextField label="Initial Quantity (Q1)" value={Q1} onChange={(e) => setQ1(e.target.value)} />
            <TextField label="New Quantity (Q2)" value={Q2} onChange={(e) => setQ2(e.target.value)} />
            <Button  variant="contained" color="primary" onClick={calculatePED}>Calculate</Button>
            {result && (
                <div><Alert severity="info">
                    <Typography variant="h6">
                        Price Elasticity of Demand: {result}
                    </Typography>
                    <Typography variant="subtitle1">
                        Interpretation: {interpretation}
                    </Typography></Alert>
                </div>
            )}
            </Box>

<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="h5">Income Elasticity of Demand Calculator</Typography>
            <TextField
                label="% Change in Quantity Demanded"
                variant="outlined"
                value={changeInQuantity}
                onChange={(e) => setChangeInQuantity(e.target.value)}
            />
            <TextField
                label="% Change in Income"
                variant="outlined"
                value={changeInIncome}
                onChange={(e) => setChangeInIncome(e.target.value)}
            />
            <Button variant="contained" color="primary" onClick={calculateYED}>
                Calculate YED
            </Button>
            {resultYED && (
                <><Alert severity="info">
                    <Typography variant="h6">Result: {resultYED}</Typography>
                    <Typography>{interpretYED(resultYED)}</Typography>
                    </Alert>
                </>
            )}
        </Box>
        

<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="h5">Cross-Price Elasticity of Demand Calculator</Typography>
            <TextField
                label="% Change in Quantity Demanded of Good A"
                variant="outlined"
                value={changeInQuantityA}
                onChange={(e) => setChangeInQuantityA(e.target.value)}
            />
            <TextField
                label="% Change in Price of Good B"
                variant="outlined"
                value={changeInPriceB}
                onChange={(e) => setChangeInPriceB(e.target.value)}
            />
            <Button variant="contained" color="primary" onClick={calculateXED}>
                Calculate XED
            </Button>
            {resultXED && (
                <><Alert severity="info">
                    <Typography variant="h6">Result: {resultXED}</Typography>
                    <Typography>{interpretXED(resultXED)}</Typography></Alert>
                </>
            )}
        </Box>


        </div>
    );
}

export default ElasticityCalculator;
