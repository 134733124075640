import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Container, Box } from '@mui/material';
import { Helmet } from 'react-helmet';

function UserIPInfo() {
    const [data, setData] = useState(null);

    useEffect(() => {
        async function fetchIpInfo() {
            try {
                const response = await axios.get('/api/get-ip-info');
                setData(response.data);
            } catch (error) {
                console.error("Error fetching IP info:", error);
            }
        }

        fetchIpInfo();
    }, []);

    return (
        <div>
            <Helmet>
        <title>P-Value Calculator</title>
        <meta name="description" content="Our tool not only reveals your unique IP but also provides additional information like your city, region, country, and ISP. Whether you're troubleshooting, setting up a server, or just curious, we've got you covered." />
      </Helmet>
            <h2>Your IP Information</h2>
            {data ? (
                <div>
                    <p><strong>IP Address:</strong> {data.ip}</p>
                    {data.city && <p><strong>City:</strong> {data.city}</p>}
                    {data.region && <p><strong>Region:</strong> {data.region}</p>}
                    {data.country && <p><strong>Country:</strong> {data.country}</p>}
                    {data.org && <p><strong>ISP:</strong> {data.org}</p>}
                </div>
            ) : (
                <p>Loading...</p>
            )}
            <Container>
            <Box mt={4} mb={4}>
                <Typography variant="h2" gutterBottom>
                    Discover Your IP Address and More
                </Typography>
                <Typography variant="body1" paragraph>
                    Curious about your IP address? Our tool not only reveals your unique IP but also provides additional information like your city, region, country, and ISP. Whether you're troubleshooting, setting up a server, or just curious, we've got you covered.
                </Typography>
                <Typography variant="body1" paragraph>
                    An IP address is like a home address for your computer on the internet. It's a unique string of numbers separated by periods that identifies each computer using the Internet Protocol to communicate over a network. With our tool, you can easily find out your IP and learn more about your online footprint.
                </Typography>
                <Typography variant="body1" paragraph>
                    Don't stop at just knowing your IP. Dive deeper and understand your digital presence better. It's free, fast, and user-friendly. Try it now!
                </Typography>
            </Box>
        </Container>
        </div>
    );
}

export default UserIPInfo;
