import React from 'react';
import { Link } from 'react-router-dom';
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import Typography from '@mui/material/Typography';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { Helmet } from 'react-helmet';

function Random() {
    return (
        <div>
            <Helmet>
                <title>Tools & Calculators - Random generators and pickers</title>
                <meta name="description" content="We offer a wide variety of random generators and pickers for numbers, colors, etc." />
            </Helmet>
            <h2>Random numbers, pick random items</h2>
            <Box p={4} bgcolor="background.paper" borderRadius={2} boxShadow={2}>
            <List>
                <ListItem button component={Link} to="/randomnumbergenerator">
                    <ListItemIcon><TextFieldsIcon color="action" /></ListItemIcon>
                    <ListItemText primary="Random number generator" />
                </ListItem>
            </List>
            </Box>
            <Typography variant="paragraph">
                <p>Random number picker</p>
                <p>Random name picker</p>
                <p>Random item picker</p>
                <p>Random letter generator</p>
                <p>Random word generator</p>
                <p>Random sentence generator</p>
                <p>Random paragraph generator</p>
                <p>Random password generator</p>
                <p>Random string generator</p>
                <p>Random string picker</p>
                <p>Random image generator</p>
                <p>Random color generator</p>
                <p>Random hex color generator</p>
                <p>Random rgb color generator</p>
                <p>Random hex color picker</p>
                <p>Random rgb color picker</p>
                <p>Random color picker</p>
                <p>Random color name picker</p>
                <p>Random color palette generator</p>
                <p>Random color palette picker</p>
                <p>Random color scheme generator</p>
                <p>Random color scheme picker</p>
                <p>Random color combination generator</p>
                <p>Random color combination picker</p>
                <p>Random image generator</p>
                <p>Random image picker</p>
                <p>Random image from url generator</p>
                <p>Random image from url picker</p>

                <p>Random name generator</p>
                <p>Random name picker</p>
                <p>Random name from list generator</p>
                <p>Random name from list picker</p>
                
                <h2>Coming soon!</h2>
            </Typography>

        </div>
    );
}

export default Random;