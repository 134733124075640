import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Link from '@mui/material/Link';

function CookieWarning() {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const hasConsent = localStorage.getItem('cookie-consent');
        if (!hasConsent) {
            setOpen(true);
        } else if (hasConsent === 'true') {
            // If consented, initialize Google Analytics here
        }
    }, []);

    const handleAgree = () => {
        // Initialize Google Analytics when user agrees
        localStorage.setItem('cookie-consent', 'true');
        setOpen(false);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        localStorage.setItem('cookie-consent', 'false');
        setOpen(false);
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={open}
            onClose={handleClose}
            message={
                <span>
                    We use cookies, including Google Analytics, to enhance your experience and analyze traffic. 
                    Learn more in our <Link href="/privacy-policy">privacy policy</Link>.
                </span>
            }
            action={
                <React.Fragment>
                    <Button color="secondary" size="small" onClick={handleAgree}>
                        AGREE
                    </Button>
                    <Button color="secondary" size="small" onClick={handleClose}>
                        DECLINE
                    </Button>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
        />
    );
}

export default CookieWarning;
