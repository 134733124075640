import React from 'react';
import { Link, Container, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

const FooterContainer = styled(Container)`
    background-color: #f7f7f7;  // Light gray background
    padding: 16px 0;
    width: 100%;
    text-align: center;
    border-top: 1px solid #e0e0e0;  // Subtle border at the top
`;

const StyledLink = styled(Link)`
    color: #333;  // Dark gray text
    text-decoration: none;
    margin: 0 8px;  // Add some margin to space out the links
    &:hover {
        text-decoration: underline;
    }
`;

function Footer() {
    return (
        <FooterContainer>
            <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="body2">
                    <StyledLink href="/about">About</StyledLink>
                </Typography>
                <Typography variant="body2">
                    <StyledLink href="/privacy-policy">Privacy Policy</StyledLink>
                </Typography>
                <Typography variant="body2">
                    <StyledLink href="http://eepurl.com/ixhv2U">Receive updates by email</StyledLink>
                </Typography>
                
            </Box>
        </FooterContainer>
    );
}

export default Footer;
