import React  from 'react';
import { Link } from '@mui/material';
import { Helmet } from 'react-helmet';

function Statistics () {
    return (
        <div>
            <Helmet>
                <title>Tools & Calculators - Random number generator</title>
                <meta name="description" content="Our free P-value calculator makes it easy to calculate the P-value for your statistical test. Just enter your observed value, mean, and standard deviation, and get the P-value instantly." />
            </Helmet>
            <h1>Statistics</h1>
            <h2>Tests</h2>
            <p>Our P-value calculator makes it easy to calculate the P-value for your statistical test. Just enter your observed value, mean, and standard deviation, and get the P-value instantly.</p>
            <Link href="/p-value">P-Value Calculator</Link>
        </div>
    );
}

export default Statistics;