import React, { useState } from 'react';
import { TextField, Paper, Button, Container, Typography, Select, MenuItem, InputAdornment } from '@mui/material';

function DateTimeConverter() {
    const [inputDate, setInputDate] = useState('2023-08-14');
    const [epochTime, setEpochTime] = useState('');
    const [timeZone, setTimeZone] = useState('UTC');
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const handleDateToEpoch = () => {
        const date = new Date(inputDate);
        setEpochTime(date.getTime());
    };


    const handleDateArithmetic = () => {
        const date = new Date(inputDate);
        date.setDate(date.getDate() + parseInt(days));
        date.setHours(date.getHours() + parseInt(hours));
        date.setMinutes(date.getMinutes() + parseInt(minutes));
        date.setSeconds(date.getSeconds() + parseInt(seconds));
        setInputDate(date.toISOString());
    };

    const getCurrentDatetime = () => {
        const now = new Date();
        return now.toISOString();
    };

    const getCurrentEpoch = () => {
        const now = new Date();
        return now.getTime();
    };

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [duration, setDuration] = useState('');

    const timeZones = ["UTC", "GMT", "PST", "EST", "CET", "IST", "AEST"]; // Add more as needed

    const calculateDuration = () => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const diff = end - start;
        const daysDiff = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hoursDiff = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutesDiff = Math.floor((diff / (1000 * 60)) % 60);
        const secondsDiff = Math.floor((diff / 1000) % 60);
        setDuration(`${daysDiff} days, ${hoursDiff} hours, ${minutesDiff} minutes, ${secondsDiff} seconds`);
    };

    const [epochInput, setEpochInput] = useState('');
    const [epochOutput, setEpochOutput] = useState('');
    const [outputDate, setOutputDate] = useState('');


    const [secondInputDate, setSecondInputDate] = useState('');
    const [timeDifference, setTimeDifference] = useState('');
    const [selectedTimezone, setSelectedTimezone] = useState('UTC');

    const timezones = ["UTC", "America/New_York", "Europe/London", "Asia/Tokyo"]; // Add more timezones as needed


    const handleTimezoneChange = (event) => {
        setSelectedTimezone(event.target.value);
        const date = new Date(inputDate);
        const options = { timeZone: event.target.value, year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        setOutputDate(date.toLocaleDateString('en-US', options));
    };

    return (
        <Container>
            <Typography variant="h4">Datetime / Timestamp / Epoch Converter</Typography>

            <Paper elevation={3} style={{ padding: '20px', maxWidth: '800px', margin: 'auto', marginTop: '50px' }}>
            <Typography variant="h6">Convert to Epoch Timestamp</Typography>
            <TextField
                label="Date and Time"
                type="datetime-local"
                value={inputDate}
                onChange={(e) => {
                    setInputDate(e.target.value);
                    let date = new Date(e.target.value);
                    setEpochOutput(date.getTime())}}
            />
            <Select value={timeZone} onChange={(e) => setTimeZone(e.target.value)}>
                <MenuItem value="UTC">UTC</MenuItem>
            </Select>
            <br />
            <Typography>Epoch timestamp: {epochOutput}</Typography>
            <Typography variant="h6">Display in Different Formats</Typography>
            <Typography>{new Date(inputDate).toLocaleString()}</Typography>
            <Typography>{new Date(inputDate).toLocaleDateString()}</Typography>
            <Typography>{new Date(inputDate).toLocaleTimeString()}</Typography>

            <Typography variant="h6">Timezone Conversion</Typography>
            <Select value={selectedTimezone} onChange={handleTimezoneChange}>
                {timezones.map((timezone) => (
                    <MenuItem key={timezone} value={timezone}>
                        {timezone}
                    </MenuItem>
                ))}
            </Select>
            <Typography>Datetime in selected timezone: {outputDate}</Typography>


            </Paper>

            <Paper elevation={3} style={{ padding: '20px', maxWidth: '800px', margin: 'auto', marginTop: '50px' }}>
            <Typography variant="h6">Convert from Epoch Timestamp to Datetime</Typography>
            <TextField
                label="Enter Epoch Timestamp"
                value={epochInput}
                onChange={(e) => {setEpochInput(e.target.value);
                    let date = new Date(Number(e.target.value));
                    setOutputDate(date.toLocaleString());            
                }}
            />
            <Typography>Datetime: {outputDate}</Typography>
            </Paper>


            <Paper elevation={3} style={{ padding: '20px', maxWidth: '800px', margin: 'auto', marginTop: '50px' }}>
            <Typography variant="h6">Current Timestamp</Typography>
            <Typography>Datetime: {getCurrentDatetime()}</Typography>
            <Typography>Epoch: {getCurrentEpoch()}</Typography>
            <Typography variant="h6">Convert to Different Timezones</Typography>
            <Select value={timeZone} onChange={(e) => setTimeZone(e.target.value)}>
                {timeZones.map((zone) => (
                    <MenuItem key={zone} value={zone}>{zone}</MenuItem>
                ))}
            </Select>
            </Paper>

            <Paper elevation={3} style={{ padding: '20px', maxWidth: '800px', margin: 'auto', marginTop: '50px' }}>
            <Typography variant="h6">Date Arithmetic</Typography>
            <TextField label="Days" value={days} onChange={(e) => setDays(e.target.value)} type="number" InputProps={{ startAdornment: <InputAdornment position="start">+</InputAdornment> }} />
            <TextField label="Hours" value={hours} onChange={(e) => setHours(e.target.value)} type="number" InputProps={{ startAdornment: <InputAdornment position="start">+</InputAdornment> }} />
            <TextField label="Minutes" value={minutes} onChange={(e) => setMinutes(e.target.value)} type="number" InputProps={{ startAdornment: <InputAdornment position="start">+</InputAdornment> }} />
            <TextField label="Seconds" value={seconds} onChange={(e) => setSeconds(e.target.value)} type="number" InputProps={{ startAdornment: <InputAdornment position="start">+</InputAdornment> }} />
            <Button variant="contained"  onClick={handleDateArithmetic}>Calculate</Button>
            </Paper>

            <Paper elevation={3} style={{ padding: '20px', maxWidth: '800px', margin: 'auto', marginTop: '50px' }}>
            <Typography variant="h4">How much time is there between two dates/times?</Typography>
            <Typography variant="h6">Time Duration Calculator</Typography>
            <TextField
                label="Start Date and Time"
                type="datetime-local"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
            />
            <TextField
                label="End Date and Time"
                type="datetime-local"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                /><Button variant="contained"  onClick={calculateDuration}>Calculate Duration</Button>
                <Typography>Duration: {duration}</Typography>
            </Paper>

        </Container>
    );
}

export default DateTimeConverter;
